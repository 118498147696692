var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.updateReport()
                    },
                  },
                },
                [
                  _c("div", { attrs: { id: "pagelayout" } }, [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "block" }, [
                          _c("div", [
                            _c("div", { staticClass: "field" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.displayName,
                                      expression: "input.displayName",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "name" },
                                  domProps: { value: _vm.input.displayName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "displayName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(2),
                              _c("div", { staticClass: "control select" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.input.typeCode,
                                        expression: "input.typeCode",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.input,
                                          "typeCode",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.reportTypesArr.filter(function (type) {
                                      return type.category === "client"
                                    }),
                                    function (opt) {
                                      return _c(
                                        "option",
                                        {
                                          key: opt.code,
                                          domProps: { value: opt.code },
                                        },
                                        [_vm._v(_vm._s(opt.name))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _vm._m(3),
                                _c("kendo-datepicker", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    id: "uploaddate",
                                    name: "uploaddate",
                                    required: "required",
                                    format: "yyyy-MM-dd",
                                  },
                                  on: { change: _vm.onUploadDateChange },
                                  model: {
                                    value: _vm.input.uploadDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.input, "uploadDate", $$v)
                                    },
                                    expression: "input.uploadDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "field" }, [
                              _c("label", { staticClass: "label is-size-7" }, [
                                _vm._v("Report Year Override"),
                              ]),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.yearOverride,
                                      expression: "input.yearOverride",
                                    },
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger":
                                      _vm.inputError &&
                                      _vm.inputError.yearOverride,
                                  },
                                  attrs: {
                                    type: "text",
                                    name: "typecode",
                                    min: "4",
                                    max: "4",
                                  },
                                  domProps: { value: _vm.input.yearOverride },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "yearOverride",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.inputError &&
                                      _vm.inputError.yearOverride,
                                    expression:
                                      "inputError && inputError.yearOverride",
                                  },
                                ],
                                staticClass:
                                  "has-background-warning has-text-white",
                                staticStyle: {
                                  padding: "0.5rem 1rem 1rem 1rem",
                                  "margin-bottom": "1rem",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "has-text-weight-bold" },
                                  [_vm._v("Error")]
                                ),
                                _c("ul", { attrs: { id: "example-1" } }, [
                                  _c(
                                    "li",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.inputError.yearOverride)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _vm.$hasPermissions(
                                  _vm.clientSession,
                                  ["CLIENT_REPORTS"],
                                  2
                                )
                                  ? _c("file-pond", {
                                      ref: "pond2",
                                      attrs: {
                                        name: "file",
                                        "label-idle":
                                          "<div><span class='icon' style='padding-top:20px; font-size: 3rem;'><i class='fas fa-3x fa-cloud-upload-alt'></i></span></div><div>Click here or drop a file here to replace this report</div>",
                                        "allow-multiple": "false",
                                        "accepted-file-types":
                                          "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        files: _vm.input.files,
                                        dropOnElement: false,
                                        dropOnPage: true,
                                      },
                                      on: {
                                        addfile: _vm.onFileAdd,
                                        removefile: _vm.removeFile,
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.length > 0,
                                        expression: "errors.length>0",
                                      },
                                    ],
                                    staticClass:
                                      "has-background-warning has-text-white",
                                    staticStyle: {
                                      padding: "0.5rem 1rem 1rem 1rem",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "has-text-weight-bold" },
                                      [_vm._v("Error")]
                                    ),
                                    _c(
                                      "ul",
                                      { attrs: { id: "example-1" } },
                                      _vm._l(_vm.errors, function (err, idx) {
                                        return _c(
                                          "li",
                                          {
                                            key: err + idx,
                                            staticStyle: {
                                              "margin-top": "5px",
                                            },
                                          },
                                          [_vm._v(_vm._s(err))]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "padding-top": "40px" },
                                    attrs: { id: "report-file-info-section" },
                                  },
                                  [
                                    _vm._m(4),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "block",
                                        attrs: {
                                          id: "report-file-info-section-data",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.sendEmail,
                                              expression: "sendEmail",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "sendEmail",
                                            name: "sendEmail",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.sendEmail
                                            )
                                              ? _vm._i(_vm.sendEmail, null) > -1
                                              : _vm.sendEmail,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.sendEmail,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.sendEmail = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.sendEmail = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.sendEmail = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "is-size-7",
                                            staticStyle: { padding: "0px 7px" },
                                            attrs: { for: "sendEmail" },
                                          },
                                          [_vm._v("Send email notification")]
                                        ),
                                        _vm.sendEmail
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "field",
                                                staticStyle: {
                                                  "padding-top": "7px",
                                                  "padding-left": "20px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "label is-size-7",
                                                  },
                                                  [_vm._v("Email Template")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "control" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "select" },
                                                      [
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.emailTemplateId,
                                                                expression:
                                                                  "emailTemplateId",
                                                              },
                                                            ],
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.emailTemplateId =
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Select a template to use"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.emailTemplates,
                                                              function (
                                                                template
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: template.id,
                                                                    domProps: {
                                                                      value:
                                                                        template.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          template.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _c(
                          "div",
                          { attrs: { id: "institution-info-section" } },
                          [
                            _vm._m(5),
                            _c(
                              "div",
                              {
                                staticClass: "block",
                                attrs: { id: "institution-info-section-data" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "columns is-vcentered" },
                                  [
                                    _c("div", { staticClass: "column" }, [
                                      _c("div", { staticClass: "field" }, [
                                        _vm._m(6),
                                        _c("div", { staticClass: "control" }, [
                                          _c("div", { staticClass: "select" }, [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.input.institutionId,
                                                    expression:
                                                      "input.institutionId",
                                                  },
                                                ],
                                                attrs: { disabled: true },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.input,
                                                      "institutionId",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              _vm._l(
                                                _vm.institutionsArr,
                                                function (opt) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: opt.id,
                                                      domProps: {
                                                        value: opt.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(opt.id) +
                                                          " " +
                                                          _vm._s(opt.name) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "column" }, [
                                      _c(
                                        "div",
                                        { staticClass: "has-text-centered" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "label is-size-7" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      _vm.institutions.get(
                                                        _vm.input.institutionId
                                                      ) || {}
                                                    ).name
                                                  ) +
                                                  " "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      _vm.institutions.get(
                                                        _vm.input.institutionId
                                                      ) || {}
                                                    ).city
                                                  ) +
                                                  ", " +
                                                  _vm._s(
                                                    (
                                                      _vm.institutions.get(
                                                        _vm.input.institutionId
                                                      ) || {}
                                                    ).stateCode
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "padding-top": "40px" },
                            attrs: { id: "report-access-list-section" },
                          },
                          [
                            _vm._m(7),
                            _c(
                              "div",
                              {
                                staticClass: "block",
                                attrs: {
                                  id: "report-access-list-section-data",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "table",
                                    { staticClass: "table is-size-7" },
                                    [
                                      _vm._m(8),
                                      _vm._l(_vm.rMembs, function (mem) {
                                        return _c("tr", { key: mem.userId }, [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                mem.institutionIds &&
                                                  mem.institutionIds.length ===
                                                    1
                                                  ? mem.institutionIds[0]
                                                  : "-"
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.usersMap.get(mem.userId)
                                                  .firstName +
                                                  " " +
                                                  _vm.usersMap.get(mem.userId)
                                                    .lastName
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.usersMap.get(mem.userId)
                                                  .email
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.usersMap.get(mem.userId)
                                                  .role
                                              )
                                            ),
                                          ]),
                                        ])
                                      }),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "padding-top": "40px" },
                            attrs: { id: "report-file-info-section" },
                          },
                          [
                            _vm._m(9),
                            _c(
                              "div",
                              {
                                staticClass: "block",
                                attrs: { id: "report-file-info-section-data" },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "table",
                                    { staticClass: "table is-size-7" },
                                    [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "is-narrow",
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [_vm._v("File Name:")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.input.displayName)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [_vm._v("File Type:")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getFileType(
                                                  _vm.input.extension
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [_vm._v("File Size:")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("prettyBytes")(
                                                  _vm.input.fileSize
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [_vm._v("Download:")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-bottom": "none",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "" + _vm.input.path,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-download fa-2x",
                                                }),
                                              ]
                                            ),
                                            _vm._v("   "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "#",
                                                  target: "_blank",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.openReport()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-eye fa-2x",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { id: "buttongroup" },
                    },
                    [
                      _c("div", { staticClass: "field is-grouped" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-accent has-text-white",
                              attrs: {
                                type: "submit",
                                disabled:
                                  _vm.isPageDisabled === true ||
                                  _vm.isSaving ||
                                  !_vm.$hasPermissions(
                                    _vm.clientSession,
                                    ["CLIENT_REPORTS"],
                                    2
                                  ),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.isSaving ? "Saving" : "Save") +
                                  " "
                              ),
                              _vm.isSaving
                                ? _c("spin-loader", {
                                    staticStyle: { "margin-left": ".75rem" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function ($event) {
                                  _vm.resetForm()
                                  _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Report Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Report Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Report Type "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Report Upload Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          "margin-bottom": "10px",
          padding: "0px 0px px 0px",
          "border-bottom": "solid 1px lightgray",
        },
        attrs: { id: "report-file-info-section-heading" },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Notifications"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
        attrs: { id: "institution-info-section-heading" },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Institution Information"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Institution "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          "margin-bottom": "10px",
          padding: "0px 0px px 0px",
          "border-bottom": "solid 1px lightgray",
        },
        attrs: { id: "report-access-list-section-heading" },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Report Access List"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "th",
        { staticClass: "is-narrow", staticStyle: { "border-bottom": "none" } },
        [_vm._v("Institution")]
      ),
      _c("th", { staticStyle: { "border-bottom": "none" } }, [_vm._v("Name")]),
      _c("th", { staticStyle: { "border-bottom": "none" } }, [
        _vm._v("Email Address"),
      ]),
      _c("th", { staticStyle: { "border-bottom": "none" } }, [_vm._v("Role")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          "margin-bottom": "10px",
          padding: "0px 0px px 0px",
          "border-bottom": "solid 1px lightgray",
        },
        attrs: { id: "report-file-info-section-heading" },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Report File Information"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }